<template>
    <div class='analysisHeader'>
        <div class="iconimg">
            <img class="img" :src="`${this.$imgDomain}/www/HealthingCore/icon11.png`" alt="">
        </div>
        <div class="Title">
            <div class="text">全身器官功能 & 储备能力分析</div>
            <div class="text">ANALYSIS OF SYSTEMIC ORGAN FUNCTION AND RESERVE CAPACITY</div>
        </div>
        <div class="waveImg">
            <img  class="img" :src="`${this.$imgDomain}/www/HealthingCore/icon6.png`" alt="">
        </div>
    </div>
</template>

<script>

export default {
    components: {
        
    },
    data() {
        return {
            
        };
    },
    props:['bagColor'],
    created() {
        
    },
    mounted() {
        
    },
    methods: {
        
    },
    watch: {
        
    },
    computed: {
        
    },
}
</script>
<style lang='scss' scoped>
.analysisHeader {
    min-height: 50px;
    width: 100%;
    border-radius: 15px 15px 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    overflow: hidden;
    color: #fff;
    background: url('https://gotocloud8-open-sx.oss-cn-hangzhou.aliyuncs.com/www/HealthingCore/bag3.png');
    background-repeat:no-repeat;
    background-position:center;
    .iconimg {
        width: 80px;
    }
    .waveImg {
        width: 30px;
        height: 30px;
    }
    .Title {
        width: calc(100% - 130px);
        padding-left: 10px;
        border-left: 2px solid #fff;
        margin: 0 10px;
        .text {
            overflow:hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
    .img {
        width: 100%;
        height: 100%;
    }
}
</style>